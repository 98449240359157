body {
  margin: 0;
  background-color: #f5f5fa;
}

.loading {
  width: 100%;
  height: 100%;
  z-index: 1000;
  position: absolute;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: table;
  background-color: grey;
  opacity: 0.5;
}

.spanImg {
  display: table-cell;
  vertical-align: middle;
}
.imgloading {
  width: 50px;
}

.otp-field {
  width: 40px;
  margin-top: 40px;
  margin-right: 10px;
  padding-left: 12px;
  height: 40px;
  border: 1px solid rgb(186, 189, 192);
  border-radius: 5px;
  opacity: 1;
}

.rotateText {
  transform: matrix(0.85, -0.53, 0.53, 0.85, 0, 0);
}

.selected {
  border: 2px solid rgb(0, 0, 255);
}

.sticky-wrapper {
  position: relative;
  height: 3rem; /* We need to change this value */
}

.sticky .sticky-inner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.noResultText {
  text-align: center;
  padding: 40px;
}
